import React, { Fragment } from 'react';
import StateBar from './StateBar';

const Threads = ({usedThreads}) => {
    return (
        <Fragment>
            <StateBar total={650} used={usedThreads} threshold1={92.3} threshold2={100}/>
        </Fragment>
    );
};

export default Threads;