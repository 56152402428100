import React, { Fragment } from 'react';
import StateBar from './StateBar';

const Frequency = ({ usedFrequency, limitFrequency }) => {
  return (
      <Fragment>
          <StateBar total={limitFrequency} used={usedFrequency}/>
      </Fragment>
  );
};

export default Frequency;