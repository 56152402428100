import React, {Fragment, useContext} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Offline, Online } from 'react-detect-offline';
import ServerStatus from './components/ServerStatus';
import ServerDetail from './components/ServerDetail';
import Loading from "./components/Loading";
import NotLoggedIn from "./components/NotLoggedIn";
import {SettingsContext} from './contexts/SettingsContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavBar from './components/NavBar';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MessageBox from './components/MessageBox';
import { useAuth0 } from './react-auth0-wrapper';

function App() {
    const { isAuthenticated, loading } = useAuth0();
    const {selectedTheme} = useContext(SettingsContext);

    const theme = createMuiTheme({
        palette: {
            type: selectedTheme,
            primary: {
                light: '#e6273d',
                main: '#e2011b',
                dark: '#d10018'
            }
        },
        breakpoints: {
            values: {
                sm: 790,
                md: 1500,
                lg: 1600,
                xl: 1860
            }
        }
    });

    if (loading) {
        return (
            <Fragment>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Loading />
                </ThemeProvider>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <NavBar />
                    <Offline>
                        <MessageBox title="You're offline!" text="Please check your internet connection."/>
                    </Offline>

                    <Online>
                        <Switch>
                            <Route exact path="/"
                                   render={() => isAuthenticated ? <ServerStatus/> :
                                       <NotLoggedIn/>}/>
                            <Route path="/servers/:cluster/:clusterGroup/:serverName" render={props => isAuthenticated ?
                                <ServerDetail {...props}/> : <NotLoggedIn/>}/>
                        </Switch>
                    </Online>
                </Router>
            </ThemeProvider>
        </Fragment>
    );
}

export default App;
