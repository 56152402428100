import React, { Fragment } from 'react';
import StateBar from './StateBar';

const Load = ({ usedLoadLevel, limitFrequency, usedLoad }) => {
    return (
        <Fragment>
            <StateBar showValues={[usedLoadLevel, usedLoad]} total={limitFrequency} used={usedLoadLevel} threshold1={80} threshold2={100}/>
        </Fragment>
    );
};

export default Load;
