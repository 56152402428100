import React from 'react';
import TableRow from '@material-ui/core/TableRow';

import Name from './Name';
import Status from './Status';
import Memory from './Memory';
import Load from './Load';
import Threads from './Threads';
import Frequency from './Frequency';
import {makeStyles} from "@material-ui/styles";
import TableCell from "@material-ui/core/TableCell";
import useServerDataFetching from "../hooks/useServerDataFetching";

const useStyles = makeStyles(theme => (
    {
        tableRow: {
            '&:last-child .MuiTableCell-root': {
                borderBottom: 'none'
            }
        },
        tableCell: {
            padding: ' 4px 24px 4px 16px',
            fontSize: '12px',
            position: 'relative',
            [theme.breakpoints.down('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '5px',
                paddingRight: '5px',
                '&:first-child:not(.MuiTableCell-head)': {
                    paddingLeft: 0,
                    paddingRight: '0'
                }
            }
        },
        name: {
            paddingLeft: '5px',
            paddingRight: '15px'
        },
        status: {
            paddingRight: '30px',
            [theme.breakpoints.down('md')]: {
                paddingRight: '25px'
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: '10px'
            }
        },
    }
));

function Server({cluster, clusterGroup, server}) {
    const classes = useStyles();
    const { serverName, name, serverIp } = server;
    const { status, response, usedMemory, freeMemory, load, loadLevel, threads, databaseFrequency, requestFrequency } = useServerDataFetching(serverName).data;

    return (
        <TableRow className={classes.tableRow} component="div" variant="body">
            <TableCell component="div" variant="body" className={`${classes.tableCell} ${classes.name}`}>
                <Name cluster={cluster} clusterGroup={clusterGroup} name={name} serverName={serverName} ip={serverIp}/>
            </TableCell>
            <TableCell component="div" variant="body" className={`${classes.tableCell} ${classes.status}`}>
                <Status className={classes.tableCell} usedStatus={status} usedResponse={response}/>
            </TableCell>
            <TableCell component="div" variant="body" className={classes.tableCell}>
                <Memory usedMemory={usedMemory} freeMemory={freeMemory}/>
            </TableCell>
            <TableCell component="div" variant="body" className={classes.tableCell}>
                <Load className={classes.tableCell} usedLoad={load} usedLoadLevel={loadLevel} limitFrequency="1"/>
            </TableCell>
            <TableCell component="div" variant="body" className={classes.tableCell}>
                <Threads className={classes.tableCell} usedThreads={threads}/>
            </TableCell>
            <TableCell component="div" variant="body" className={classes.tableCell}>
                <Frequency className={classes.tableCell} usedFrequency={databaseFrequency} limitFrequency="1000"/>
            </TableCell>
            <TableCell component="div" variant="body" className={classes.tableCell}>
                <Frequency className={classes.tableCell} usedFrequency={requestFrequency} limitFrequency="100"/>
            </TableCell>
        </TableRow>
    );
}

export default Server;