import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => (
    {
        stateBarValue: {
            maxWidth: '130px',
            overflow: 'visible',
            whiteSpace: 'nowrap',
            position: 'relative',
            lineHeight: 1.2,
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        fullStateBar: {
            '&+svg': {
                display: 'none',
                [theme.breakpoints.down('xs')]: {
                    display: 'inline-block',
                    right: 0
                }
            }
        },
        fullStatus: {
            position: 'absolute',
            left: '-12px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            padding: '12px',
            transition: '.2s all ease',
            color: 'inherit',
            boxShadow: 'none',
            [theme.breakpoints.down('xs')]: {
                right: 'unset',
                paddingRight: '20px',
                '& svg': {
                    display: 'none'
                }
            }
        },
        icon: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '5px',
            color: '#AEEA00',
            [theme.breakpoints.down('lg')]: {
                right: 0,
                transform: 'translateY(-50%) scale(.6)'
            }
        },
        iconProgress: {
            right: '9px',
            color: '#90CAF9',
            transform: 'none',
            top: '10px',
            width: '17px',
            height: '17px',
            [theme.breakpoints.down('lg')]: {
                right: '6px',
                top: '13px',
                width: '12px',
                height: '12px'
            },
            [theme.breakpoints.down('xs')]: {
                top: '8px'
            }
        },
        iconError: {
            color: '#F44336',
            zIndex: 2,
            right: '-20px',
            [theme.breakpoints.down('lg')]: {
                right: '-10px'
            }
        }
    }
));

const Status = ({usedStatus}) => {
    const classes = useStyles();

    const statusIcon = () => {
        if (usedStatus === 'Warm-Online') {
            return <DoneIcon className={classes.icon} />
        } else if (usedStatus.includes('Warming') || usedStatus.includes('Loading')) {
            return <CircularProgress className={`${classes.icon} ${classes.iconProgress}`} variant="indeterminate" size=""/>
        } else if (usedStatus.includes('Error')) {
            return <ErrorIcon className={`${classes.icon} ${classes.iconError}`}/>
        }
    };

    return (
        <Fragment>
            <div className={`${classes.stateBarValue} ${usedStatus.includes('Error') && classes.fullStateBar}`}
                 style={{color: usedStatus.includes('Error') ? '#F44336' : 'inherit'}}>
                {!usedStatus.includes('Error') && <span>{usedStatus}</span>}
                {usedStatus.includes('Error') && <Paper component="span" className={classes.fullStatus}><span>{usedStatus}</span> {statusIcon()}</Paper>}
            </div>
            {statusIcon()}
        </Fragment>
    );
};

export default Status;