import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ThemeSelect from "./ThemeSelect";
import RefreshIntervalSetting from './RefreshIntervalSetting';

export default function Settings({ isOpen, toggleSettings }) {
    return (
        <Fragment>
            <Dialog open={isOpen} onClose={toggleSettings}>
                <DialogTitle id="alert-dialog-title">Settings</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                        <ThemeSelect/>
                        <RefreshIntervalSetting/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}