import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const useStyles = makeStyles(theme => ({
    nameButtonWrapper: {
        minWidth: '77px',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto'
        }
    },
    button: {
        minWidth: 'auto',
        padding: '4px 13px',
        [theme.breakpoints.down('xs')]: {
            padding: '2px 6px',
            fontSize: '10px'
        }
    },
    icon: {
        display: 'inline-block',
        transform: 'scale(.7)',
        padding: '2px',
        marginRight: '-4px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    iconFwAdmin: {
        color: '#ec407a'
    },
    iconEcePerf: {
        color: '#9CCC65'
    },
    iconProbe: {
        color: '#26C6DA',
        padding: '0 .245em',
        '& span': {
            marginTop: '-3px'
        }
    }
}));

const Name = ({ cluster, clusterGroup, name, serverName, ip }) => {
    const classes = useStyles();

    return (
            <Fragment>
               <span className={classes.nameButtonWrapper}>
                   <Button title={name} size="small" href={`/servers/${cluster}/${clusterGroup}/${serverName}`.toLowerCase()} className={classes.button}>{name}</Button>
               </span>
                <IconButton title="FW-Admin" className={`${classes.icon} ${classes.iconFwAdmin}`} target="blank" href={`http://${ip}:8080/framework-admin/index.html`}>
                    <SettingsIcon />
                </IconButton>
                <IconButton title="ECE-Perf" variant="contained" className={`${classes.icon} ${classes.iconEcePerf}`} target="blank" href={`http://${ip}:8080/escenic-admin/pages/performance.jsp`}>
                    <ShowChartIcon />
                </IconButton>
                <IconButton title="Probe" variant="contained" className={`${classes.icon} ${classes.iconProbe}`} target="_blank" href={`http://admin:secret@${ip}:8080/probe/threads.htm?d-5474-s=4&d-5474-o=2`}>
                    <span>&psi;</span>
                </IconButton>
            </Fragment>
    );
};

export default Name;
