import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings'
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-wrapper';

import Settings from './Settings';

export default function NavBar({selectedTheme, handleThemeChange}) {
    const { isAuthenticated, logout } = useAuth0();
    const mobileBreakpoint = '790px';
    const hideOnMobile = useMediaQuery(`(min-width: ${mobileBreakpoint})`);
    const showOnMobile = useMediaQuery(`(max-width: ${mobileBreakpoint})`);
    const [isSettingsOpen, openSettings] = React.useState(false);
    const [isDrawerOpen, openDrawer] = React.useState(false);

    function toggleSettings() {
        openSettings(!isSettingsOpen);
    }

    function toggleDrawer() {
        openDrawer(!isDrawerOpen);
    }

    const useStyles = makeStyles(theme => {
        return ({
            root: {
                flexGrow: 1,
                overflow: 'hidden',
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },
            titleLinkWrapper: {
                display: 'flex',
                flexGrow: 1
            },
            titleLink: {
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                [theme.breakpoints.down('xs')]: {
                    '& strong': {
                        display: 'none'
                    }
                }
            },
            title: {
                flexGrow: 1,
                marginLeft: '10px',
                fontWeight: 100,
                '& strong': {
                    fontWeight: 700
                }
            },
            toolBar: {
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }
            },
            button: {
                transition: '.2s all ease',
                textTransform: 'none',
                borderRadius: 0,
                lineHeight: 3.8,
                padding: '0 16px',
                position: 'relative',
                opacity: '.5',
                fontWeight: 'bold',
                '&:after': {
                    transition: '.2s all ease',
                    height: 0,
                    position: 'absolute',
                    content: '""',
                    width: 'calc(100% - 32px)',
                    bottom: '-1px',
                },
                '&:hover': {
                    opacity: 1,
                    background: 'none'
                }
            },
            listItem: {
                opacity: '.7'
            },
            menuIcon: {
              marginRight: '-23px',
                '&:hover': {
                    opacity: .7
                }
            },
            active: {
                opacity: 1,
                '&:after': {
                    background: theme.palette.primary.main,
                    height: '5px',
                },
                '&:hover': {
                    background: 'none'
                },
                '&:hover:after': {
                    width: '100%'
                }
            },
            list: {
                width: 'auto'
            }
        });
    });

    const SlideList = () => {
        return (
            <div className={classes.list} role="presentation" onClick={toggleDrawer}>
                <List>
                    <ListItem button component={NavLinkRef} isActive={(match, location) => (match || location.pathname.includes('/servers/'))}
                              exact to="/" className={classes.listItem}><ListItemText primary="Servers" /></ListItem>
                    <Divider />
                    <ListItem button className={classes.listItem}><ListItemText onClick={toggleSettings} primary="Settings" /></ListItem>
                    <Divider />
                    <Fragment>
                        {isAuthenticated && <ListItem button className={classes.listItem}><ListItemText onClick={() => logout({})} primary="Log out" /></ListItem>}
                    </Fragment>
                </List>
            </div>
        )
    };

    const classes = useStyles();
    const NavLinkRef = React.forwardRef((props, ref) => <div ref={ref}><NavLink activeClassName={classes.active} {...props} /></div>);

    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar variant="dense" className={classes.toolBar}>
                <div className={classes.titleLinkWrapper}>
                    <NavLink to="/" className={classes.titleLink}>
                        <img alt="Logo" height="20" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNTAuNTggMTUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE1MC41OCAxNTAuNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNFMjAxMUI7fQo8L3N0eWxlPgo8ZyBpZD0iZzE0NCIgdHJhbnNmb3JtPSJtYXRyaXgoMTMuODA4NjA3LDAsMCwtMTMuODA4NjA3LDQ3MC42MzY2NSwzMjYuNTk3MjkpIj4KCTxwYXRoIGlkPSJwYXRoMTQ2IiBjbGFzcz0ic3QwIiBkPSJNLTI1LjExLDIzLjY1aC0xLjY5bDEuOTMtNS40NWwtMS45My01LjQ1aDEuNjlsMS45Myw1LjQ1TC0yNS4xMSwyMy42NXoiLz4KPC9nPgo8ZyBpZD0iZzE0OCIgdHJhbnNmb3JtPSJtYXRyaXgoMTMuODA4NjA3LDAsMCwtMTMuODA4NjA3LDQzNy4wNjkyNSwzMjYuNTk3MjkpIj4KCTxwYXRoIGlkPSJwYXRoMTUwIiBjbGFzcz0ic3QwIiBkPSJNLTI1LjExLDIzLjY1aC0xLjY5bDEuOTMtNS40NWwtMS45My01LjQ1aDEuNjlsMS45Myw1LjQ1TC0yNS4xMSwyMy42NXoiLz4KPC9nPgo8ZyBpZD0iZzE1MiIgdHJhbnNmb3JtPSJtYXRyaXgoMTMuODA4NjA3LDAsMCwtMTMuODA4NjA3LDQwMy42NjM0NywzMjYuNTk3MjkpIj4KCTxwYXRoIGlkPSJwYXRoMTU0IiBjbGFzcz0ic3QwIiBkPSJNLTI1LjExLDIzLjY1aC0xLjY5bDEuOTMtNS40NWwtMS45My01LjQ1aDEuNjlsMS45Myw1LjQ1TC0yNS4xMSwyMy42NXoiLz4KPC9nPgo8ZyBpZD0iZzE1NiIgdHJhbnNmb3JtPSJtYXRyaXgoMTMuODA4NjA3LDAsMCwtMTMuODA4NjA3LDM3MC4wOTQ3NSwzMjYuNTk3MjkpIj4KCTxwYXRoIGlkPSJwYXRoMTU4IiBjbGFzcz0ic3QwIiBkPSJNLTI1LjExLDIzLjY1aC0xLjY5bDEuOTMtNS40NWwtMS45My01LjQ1aDEuNjlsMS45Myw1LjQ1TC0yNS4xMSwyMy42NXoiLz4KPC9nPgo8L3N2Zz4K" />
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            <strong>Funke Digital</strong> ServerStatus
                        </Typography>
                    </NavLink>
                </div>
                {hideOnMobile && isAuthenticated &&
                    <Fragment>
                        <Button color="inherit" className={classes.button} component={NavLinkRef} exact to="/"
                                isActive={(match, location) => (match || location.pathname.includes('/servers/'))}>Servers</Button>
                        <Button className={classes.button} onClick={toggleSettings}>&nbsp;<SettingsIcon />&nbsp;</Button>
                    </Fragment>
                }
                {showOnMobile && <Button className={`${classes.button} ${classes.menuIcon}`} onClick={toggleDrawer}><MenuIcon/></Button>}
                {hideOnMobile &&
                    <Fragment>
                        {isAuthenticated &&  <Button color="inherit" className={classes.button} onClick={() => logout({})}>Log out</Button>}
                    </Fragment>
                }
            </Toolbar>
            <Settings isOpen={isSettingsOpen} toggleSettings={toggleSettings} handleThemeChange={handleThemeChange} selectedTheme={selectedTheme} />
            <SwipeableDrawer onClose={toggleDrawer} onOpen={toggleDrawer} open={isDrawerOpen} anchor="right">
                <SlideList />
            </SwipeableDrawer>
        </AppBar>
    );
}