import React, { Fragment } from 'react';
import { convertMem } from '../helpers';
import StateBar from './StateBar';

const Memory = ({ usedMemory, freeMemory }) => {
  const fullMemory = parseInt(usedMemory) + parseInt(freeMemory);
  const used = convertMem(usedMemory, 3);
  const free = convertMem(freeMemory, 3);

  return (
      <Fragment>
          {usedMemory && <StateBar total={fullMemory} valueSuffix="GB" used={usedMemory} threshold1={95} showValues={[used, free]}/>}
      </Fragment>
  );
};

export default Memory;
