import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MessageBox({ title, text }) {
    const [open, setOpen] = React.useState(true);

    function handleClose() {
        setOpen(false);
    }

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose} disableBackdropClick={true} disableEscapeKeyDown={true}>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}