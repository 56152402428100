import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useAuth0} from "../react-auth0-wrapper";
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core";

export default function NotLoggedIn() {
    const { loginWithRedirect } = useAuth0();
    const [open] = React.useState(true);

    const useStyles = makeStyles(() => {
        return ({
            button: {
                background: '#e2011b',
                width: '100%',
                color: '#fff'
            }
        });
    });
    const classes = useStyles();

    return (
        <Fragment>
            <Dialog open={open} disableBackdropClick={true} disableEscapeKeyDown={true}>
                <DialogTitle id="alert-dialog-title">You're not logged in</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Button onClick={() => loginWithRedirect({})}  className={classes.button}>
                            Log in now
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}