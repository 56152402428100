import React, { createContext, useState } from 'react';
import mainConfig from '../config/mainConfig';

export const SettingsContext = createContext();

const SettingsContextProvider = (props) => {
    const [selectedTheme, setTheme] = useState(localStorage.getItem('theme') || mainConfig.theme);
    const [refreshInterval, setRefreshInterval] = useState(parseInt(localStorage.getItem('refreshInterval')) || mainConfig.refreshInterval);

    const handleThemeChange = event => {
        localStorage.setItem('theme', event.target.value);
        setTheme(event.target.value);
        document.body.className = selectedTheme;
    };

    const handleRefreshIntervalChange = (event, newValue) => {
        const refreshInterval = newValue * 1000;
        localStorage.setItem('refreshInterval', refreshInterval.toString());
        setRefreshInterval(refreshInterval);
    };

    return (
        <SettingsContext.Provider value={{selectedTheme, handleThemeChange, refreshInterval, handleRefreshIntervalChange}}>
            {props.children}
        </SettingsContext.Provider>
    )
};

export default SettingsContextProvider;