import React, { Fragment } from 'react';
import ClusterGroup from './ClusterGroup';
import Grid from '@material-ui/core/Grid';

const Cluster = ({ clusterProps }) => {
    const clusterGroups = clusterProps.cluster_groups.map((clusterGroup) => {
        return (
            <Grid item xs={12} lg={6} key={clusterGroup.name || clusterGroup.server[0].name}>
                <ClusterGroup cluster={clusterProps.name} clusterGroup={clusterGroup} />
            </Grid>
        )
    });

    return (
        <Fragment>
            {clusterGroups}
        </Fragment>
    );
};

export default Cluster;