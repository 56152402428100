import React, {useContext} from 'react';
import {SettingsContext} from '../contexts/SettingsContext';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
       marginBottom: 20,
        minWidth: 174,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function ThemeSelect() {
    const classes = useStyles();
    const {selectedTheme, handleThemeChange} = useContext(SettingsContext);

    return (
        <form className={classes.root} autoComplete="off" >
            <FormControl className={classes.formControl} component="div">
                <InputLabel htmlFor="select-theme">Theme</InputLabel>
                <Select value={selectedTheme} onChange={handleThemeChange} inputProps={{name: 'theme', id: 'select-theme', }}>
                    <MenuItem value="dark">dark</MenuItem>
                    <MenuItem value="light">light</MenuItem>
                </Select>
            </FormControl>
        </form>
    );
}
