import React, {Fragment, useContext} from 'react';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import {SettingsContext} from '../contexts/SettingsContext';
import useServerDataFetching from '../hooks/useServerDataFetching';

import clusterConfig from '../config/clusterConfig';
import TableRow from '@material-ui/core/TableRow';

import Status from './Status';
import Memory from './Memory';
import Load from './Load';
import Threads from './Threads';
import Frequency from './Frequency';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SortIcon from '@material-ui/icons/Sort';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const useStyles = makeStyles(theme => ({
    tableRow: {
        '& span:first-child': {
            display: 'flex',
            transform: 'none',
            padding: '0 15px 0 0'
        },
        '&:last-child .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    tableCell: {
        position: 'relative',
        '& div': {
          maxWidth: 'none'
        },
        '& .MuiSvgIcon-root': {
            transform: 'translateY(-50%)',
            marginRight: '10px'
        },
        '& .MuiCircularProgress-root': {
            top: '15px',
            right: '10px',
            [theme.breakpoints.down('lg')]: {
                width: '17px',
                height: '17px'
            },
            [theme.breakpoints.down('xs')]: {
                top: '13px'
            }
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            paddingRight: '12px'
        },
        '& .MuiPaper-root': {
            position: 'relative'
        },
        '& span': {
            whiteSpace: 'normal'
        }
    },
    status: {
        [theme.breakpoints.down('xs')]: {
            '& div': {
                display: 'block'
            }
        }
    },
    subtitle: {
        display: 'block',
        color: 'grey',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px'
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    icon: {
        marginRight: '5px'
    },
    link: {
        textDecoration: 'none',
        '&:visited,&:active': {
            textDecoration: 'none'
        },
        '& .MuiButton-outlined': {
            [theme.breakpoints.down('xs')]: {
                padding: '5px 10px'
            }
        }
    }
}));

function Server({match}) {
    const classes = useStyles();
    const {refreshInterval} = useContext(SettingsContext);

    const cluster = clusterConfig.find(item => item.name.toLowerCase() === match.params.cluster);
    const clusterGroup = cluster['cluster_groups'].find(item => item.name.toLowerCase() === match.params.clusterGroup);
    const server = clusterGroup.server.find(item => item.serverName.toLowerCase() === match.params.serverName);

    const {serverName, name, serverIp} = server;
    const {data, lastRefresh} = useServerDataFetching(serverName);

    return (
        <Fragment>
            <Box padding={3} key={cluster.name}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{name.toUpperCase()} ({serverIp})</Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>Cluster: {`${clusterGroup.name} (${cluster.name})`} | Last refresh: {lastRefresh} (every {refreshInterval / 1000} seconds)</Typography>
                        <a href={`http://${serverIp}:8080/framework-status/status/json.jsp`} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            <Button variant="outlined" color="default"><SortIcon style={{color: '#B2EBF2'}} className={classes.icon} /> Fw-Status</Button>
                        </a>
                        <a href={`http://${serverIp}:8080/framework-admin/index.html`} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            <Button variant="outlined" className={classes.button} color="default"><SettingsIcon style={{color: '#EC407A'}} className={classes.icon} /> Fw-Admin</Button>
                        </a>
                        <a href={`http://${serverIp}:8080/escenic-admin/pages/performance.jsp`} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            <Button variant="outlined" color="default"><ShowChartIcon style={{ color: '#9CCC65' }}  className={classes.icon} /> ECE-Perf</Button>
                        </a>
                        <a href={`http://admin:secret@${serverIp}:8080/probe/threads.htm?d-5474-s=4&d-5474-o=2`} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            <Button variant="outlined" style={{paddingLeft: '35px'}} className={classes.button} color="default"><span style={{fontSize: '1.4em', position: 'absolute', left: '16px', color: '#26C6DA'}} className={classes.icon}>&psi;</span> Probe</Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                       <Paper>
                           <Table component="div">
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>Status: </TableCell>
                                   <TableCell component="div" className={`${classes.tableCell} ${classes.status}`}>
                                       <Status usedStatus={data.status} usedResponse={data.response}/>
                                   </TableCell>
                               </TableRow>
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>Memory (used/free): </TableCell>
                                   <TableCell component="div" className={classes.tableCell}>
                                       <Memory usedMemory={data.usedMemory} freeMemory={data.freeMemory}/>
                                   </TableCell>
                               </TableRow>
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>Load (rel/abs): </TableCell>
                                   <TableCell component="div" className={classes.tableCell}>
                                       <Load usedLoad={data.load} usedLoadLevel={data.loadLevel} limitFrequency="1"/>
                                   </TableCell>
                               </TableRow>
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>Threads: </TableCell>
                                   <TableCell component="div" className={classes.tableCell}>
                                       <Threads usedThreads={data.threads}/>
                                   </TableCell>
                               </TableRow>
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>DB-Frequency: </TableCell>
                                   <TableCell component="div" className={classes.tableCell}>
                                       <Frequency usedFrequency={data.databaseFrequency} limitFrequency="1000"/>
                                   </TableCell>
                               </TableRow>
                               <TableRow component="div" variant="body" className={classes.tableRow}>
                                   <TableCell component="div" className={classes.tableCell}>P-Req Hz: </TableCell>
                                   <TableCell component="div" className={classes.tableCell}>
                                       <Frequency usedFrequency={data.requestFrequency} limitFrequency="100"/>
                                   </TableCell>
                               </TableRow>
                           </Table>
                       </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                       <Paper style={{padding: '16px'}}>
                           <JSONPretty id="json-pretty" mainStyle="font-size:14px;margin:0;background:transparent!important;line-height:18px!important;padding:3px 0 6px" data={data} theme={JSONPrettyMon} />
                       </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}

export default Server;