import React, {Fragment} from 'react';
import { percentageAndColor } from '../helpers';
import {makeStyles} from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => (
    {
        stateBarValue: {
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'space-between'
        },
        values: {
            '&:last-child': {
                [theme.breakpoints.down('xs')]: {
                    display: 'none'
                }
            }
        },
        stateBar: stateBarStyles => {
            return ({
                ...stateBarStyles,
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'block',
                height: '100%',
                transition: 'all .2s',
                maxWidth: 'calc(100% - 2px)'
            })
        }
    }
));

const StateBar = ({ showValues, total, used, threshold1, threshold2, valueSuffix }) => {
    const theme = useTheme();
    const stateBarStyles = percentageAndColor(theme.palette.type, total, used, threshold1, threshold2);
    const classes = useStyles(stateBarStyles);

    const values = showValues && showValues.map((value, index) => {
       return (
           <span key={`${index}_${value}`} className={classes.values}>
               {value} {valueSuffix && <Fragment>{valueSuffix}</Fragment>}
           </span>
       )
    });

    return (
        <Fragment>
            <div className={classes.stateBarValue}>{values || used} {(!values && valueSuffix) && <Fragment>{valueSuffix}</Fragment>}</div>
            <div className={classes.stateBar}/>
        </Fragment>
    );
};

export default StateBar;
