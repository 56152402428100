import {useContext, useEffect, useState} from 'react';
import {apiBaseUrl, defaultData} from '../config/mainConfig';
import {SettingsContext} from '../contexts/SettingsContext';
import {useAuth0} from '../react-auth0-wrapper';
import useInterval from './useInterval';
import axios from 'axios';
import {formatServerData, time} from '../helpers';

const useServerDataFetching = (serverName) => {
    const {refreshInterval} = useContext(SettingsContext);
    const [data, setData] = useState(defaultData);
    const url = `${apiBaseUrl}/${serverName}`;
    const {getTokenSilently} = useAuth0();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let lastRefresh = time();

    const fetchData = async () => {
        lastRefresh = time();
        try {
            const token = await getTokenSilently();
            const response = await axios.get(url, {
                headers: {Authorization: `Bearer ${token}`},
                timeout: refreshInterval - 1000,
                cancelToken: source.token
            });
            setData(formatServerData(response.data));
        } catch (err) {
            setData({...defaultData, status: `Error: ${err.message}`});
        }
    };

    useEffect(() => {
        fetchData();
        return () => {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        fetchData();
    }, refreshInterval);

    return {data, lastRefresh};
};

export default useServerDataFetching;