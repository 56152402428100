import React, {Fragment} from 'react';
import clusterConfig from '../config/clusterConfig';
import Cluster from './Cluster';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ServerStatus = () => {
    const clusters = clusterConfig.map((cluster) => {
        return (
            <Box padding={2} key={cluster.name}>
                <Typography variant="h6" color="inherit">
                    {cluster.name}
                </Typography>
                <Grid container spacing={3}>
                    <Cluster clusterProps={cluster}/>
                </Grid>
            </Box>
        )
    });

    return (
        <Fragment>
            {clusters}
        </Fragment>
    );
};

export default ServerStatus;
