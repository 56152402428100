import React, {useContext, useState, Fragment} from 'react';
import {SettingsContext} from '../contexts/SettingsContext';
import Slider from '@material-ui/core/Slider';
import Typography from "@material-ui/core/Typography";

const RefreshIntervalSetting = () => {
    const {refreshInterval, handleRefreshIntervalChange} = useContext(SettingsContext);
    const [displayInterval, setDisplayInterval] = useState(refreshInterval / 1000);

    const handleDisplayIntervalChange = (event, newValue) => {
      setDisplayInterval(newValue);
    };

    return (
            <Fragment>
                <Typography variant="caption">Refresh interval</Typography>
                <Slider component="div" value={displayInterval} min={5} max={60} valueLabelDisplay="auto" onChange={handleDisplayIntervalChange} onChangeCommitted={handleRefreshIntervalChange}/>
                {displayInterval} seconds
            </Fragment>
    );
};

export default RefreshIntervalSetting;