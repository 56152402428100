import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Server from './Server';

import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    h6: {
        fontSize: '12px',
        padding: '10px 0 0 12px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingTop: '3px',
            marginLeft: 0
        }
    },
    tableCell: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '4px 5px',
            '& span': {
                display: 'none'
            }
        }
    }
}));

const ClusterGroup = ({ cluster, clusterGroup }) => {
    const classes = useStyles();

    const servers = clusterGroup.server.map(server => {
        return (
            <Server cluster ={cluster} clusterGroup={clusterGroup.name} server={server} key={`${server.name}_${server.serverIp}`} />
        );
    });

    return (
        <Paper>
            {clusterGroup.name && <Typography variant="h6" className={classes.h6}>{clusterGroup.name}</Typography>}
            <Table component="div" size="small">
                <TableCell className={classes.tableCell}  component="div" variant="head">Server</TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head"><span>Status</span></TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head">Mem<span>ory (used/free)</span></TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head">Load<span> (rel/abs)</span></TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head">Threads</TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head">DB<span>-Freq.</span></TableCell>
                <TableCell className={classes.tableCell} component="div" variant="head">P-Req.<span> Hz</span></TableCell>
                {servers}
            </Table>
        </Paper>
    );
};

export default ClusterGroup;