import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";

const Loading = () => {
    const useStyles = makeStyles(() => {
        return ({
            spinner: {
                textAlign: 'center',
                width: '40px',
                height: '40px'
            },
            dialogText: {
                textAlign: 'center'
            }
        });
    });
    const classes = useStyles();

    return (
        <Fragment>
            <Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true}>
                <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.dialogText} component="div">
                        <CircularProgress className={classes.spinner} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default Loading;