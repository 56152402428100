export function convertMem(number, pow) {
    return (parseFloat(number) / Math.pow(1024, pow)).toFixed(2)
}

export function percentageAndColor(theme, total, partial, threshold1, threshold2) {
    let percentage = (100 / total) * partial;
    percentage = percentage > 100 ?  100 : percentage;
    const setBackground = () => {
        return theme === 'dark' ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.05)'
    };

    let background = setBackground();

    if (threshold1) {
        background = percentage >= threshold1 ? '#F44336' : background;
    }

    if (threshold2) {
        background = percentage >= threshold1 ? '#FFC107' : background;
        background = percentage >= threshold2 ? '#F44336' : background;
    }

    return {
        width: `${percentage}%`,
        background,
    }
}

export function formatServerData(data) {
    Object.keys(data).forEach(key => {
        if(typeof(data[key]) === 'string') {
            if(data[key].includes('.') && key !== 'status') {
                data[key] = parseFloat(data[key]).toFixed(2);
            }
        }
    });
    return data;
}

export function time(){
    const date = new Date();
    const leadingZero = (num) => `0${num}`.slice(-2);
    const formatTime = () =>
        [date.getHours(), date.getMinutes(), date.getSeconds()]
            .map(leadingZero)
            .join(':');
    return formatTime();
}